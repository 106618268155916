""// Import Swiper and modules
import Swiper, { Navigation } from 'swiper';

/**
 * Swipe Actus
 */
const KGSliderActus = () => {

    const $sliderActus = document.getElementById('kg-slider-actus');
    if (!$sliderActus) {
        return false;
    }

    const swiperActus = new Swiper($sliderActus, {
        modules: [Navigation],
        slideClass: 'kg-slider__slide',
        wrapperClass: 'kg-slider__wrapper',
        grabCursor: true,
        slidesPerView: 1,
        spaceBetween: 12,
        breakpoints: {
            640: {
                slidesPerView: 2,
                slidesPerGroup: 2
            },
            960: {
                slidesPerView: 3,
                slidesPerGroup: 3
            }
        }
    });

    if (document.querySelector('.kg-slider__navigation--actus')) {
        const button = {
            prev: document.querySelector('.kg-slider__navigation--actus-button--prev'),
            next: document.querySelector('.kg-slider__navigation--actus-button--next')
        };

        // Click Events
        if ( button.prev && button.next ) {
            button.prev.addEventListener('click', (e) => swiperActus.slidePrev());
            button.next.addEventListener('click', (e) => swiperActus.slideNext());
        }
    }
};

document.addEventListener('DOMContentLoaded', KGSliderActus);